import React from 'react';
import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import Sidebar from "./includes/Sidebar";
import Header from "./includes/Header";
import Footer from "./includes/Footer";


export default function Body() {
  const navigate = useNavigate();

  useEffect(() => {
    if (window.location.pathname === "/") {
      navigate("/comingsoon");
    }
  }, []);
  return (
    <div className='main-body'>
      {/* <Sidebar /> */}
      <div className='body'>
        {/* <Header /> */}
        <div className='body-Con-area'>
          <Outlet />
        </div>
        {/* <Footer /> */}
      </div>
    </div>
  )
}
