import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route} from "react-router-dom";

import Body from './Body';
import Dashboard from './pages/Dashboard';
import Error from './pages/Error';

import ComingSoon from './pages/comingsoon/ComingSoon';

const Jsx = () =>(
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<Body />}>
                <Route path="/comingsoon" element={<ComingSoon />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/*" element={<Error />} />
            </Route>
        </Routes>
    </BrowserRouter>
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Jsx />);

// this export default is nothing but used to reduse an error while building (not found default export on this file)
export default Jsx;
